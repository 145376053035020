/* Note: `style.css` is autogenerated from `style.scss` */
:root {
  /* Colors */
  --rrui-white-color: #ffffff;
  --rrui-black-color: #000000;
  --rrui-accent-color: #03b2cb;
  --rrui-accent-color-light: #2bd7f0;
  --rrui-error-color: #d30f00;
  /* Gray is used as `<TextInput/>` `label` placeholder color. */
  --rrui-gray-color: #888888;
  /* `<DatePicker/>` disabled day color. */
  --rrui-gray-color-light: #cacaca;
  /* Lightest Gray is used as `<DatePicker/>`'s currently mouse-overed calendar day. */
  --rrui-gray-color-lightest: #f3f3f3;
  /* Modular grid unit. */
  --rrui-unit: 0.6rem;
  /* `<TextInput/>`, `<Select/>`, `<Button/>` height. */
  --rrui-input-height: calc(var(--rrui-unit) * 3);
  /* For `small-screen/*.Fullscreen.css` styles (only if used). */
  --rrui-fullscreen-input-height: calc(var(--rrui-unit) * 4);
  /* `<TextInput multiline/>` */
  --rrui-multiline-text-height: calc(var(--rrui-unit) * 7);
  --rrui-multiline-text-input-vertical-padding: calc(var(--rrui-unit) / 2);
  /* `<TextInput/>`, `<Select/>`, `<Autocomplete/>` input side padding. */
  --rrui-input-field-side-padding: 0rem;
  /* `<TextInput/>`, `<Select/>`, `<Autocomplete/>`, etc. */
  --rrui-input-field-background-color: var(--rrui-white-color);
  --rrui-input-field-background-color-focus: var(--rrui-input-field-background-color);
  --rrui-input-field-background-color-error: var(--rrui-input-field-background-color);
  --rrui-input-field-background-color-disabled: var(--rrui-input-field-background-color);
  --rrui-input-field-transition: border 0.1s;
  --rrui-input-field-text-color: var(--rrui-black-color);
  --rrui-input-field-font-size: inherit;
  /* `<Button/>` */
  --rrui-button-side-padding: 0rem;
  --rrui-button-border-radius: 0rem;
  /* `<Button/>` colors. */
  --rrui-button-background-color: transparent;
  --rrui-button-text-color: var(--rrui-accent-color);
  /* `<Button/>` colors :active. */
  --rrui-button-background-color-active: var(--rrui-button-background-color);
  --rrui-button-text-color-active: var(--rrui-accent-color-light);
  /* `<ExpandableMenu/>` */
  --rrui-collapsible-menu-item-side-padding: calc(var(--rrui-unit) * 2.5);
  /* `<Snackbar/>` */
  --rrui-snackbar-height: calc(var(--rrui-unit) * 4);
  --rrui-snackbar-side-padding: calc(var(--rrui-unit) * 1.5);
  --rrui-snackbar-margin: calc(var(--rrui-unit) * 2);
  --rrui-snackbar-notifications-count-size: 1.3em;
  --rrui-snackbar-notifications-count-background-color: #d30f00;
  /* `<Tooltip/>` */
  --rrui-tooltip-side-padding: calc(var(--rrui-unit) * 1.5);
  --rrui-tooltip-height: calc(var(--rrui-unit) * 4);
  /* `<Switch/>` and `<Checkbox/>` */
  --rrui-control-label-margin: var(--rrui-unit);
  /* `<SlideOutMenu/>` */
  --rrui-slideout-menu-animation-duration: 220ms;
  /* Close icon */
  --rrui-close-margin: calc(var(--rrui-unit) * 2);
  --rrui-close-size: calc(var(--rrui-unit) * 2);
  /* Menu button */
  --rrui-menu-button-animation-duration: 0.25s;
  /* Border radius */
  --rrui-border-radius-big: 0.2rem;
  --rrui-border-radius-small: 0.1rem;
  /* "Cards" are `<Modal/>`, `<Select/>`, `<Autocomplete/>`, `<DatePicker/>`. */
  --rrui-card-background-color: var(--rrui-white-color);
  --rrui-card-text-color: var(--rrui-black-color);
  /* `<TextInput/>`, `<Select/>` and `<DatePicker/>` border. */
  --rrui-input-field-border-radius: 0rem;
  --rrui-input-field-border-width: 1px;
  --rrui-input-field-border-color: var(--rrui-gray-color-light);
  --rrui-input-field-border-color-focus: var(--rrui-accent-color);
  --rrui-input-field-border-color-error: var(--rrui-error-color);
  /* `<TextInput/>`, `<Select/>` and `<DatePicker/>` label. */
  --rrui-input-label-focus-top-offset: -70%;
  --rrui-input-label-focus-scale: 0.8;
  /* `<TextInput/>`, `<Select/>` and `<DatePicker/>` label color. */
  --rrui-input-field-label-color: var(--rrui-gray-color);
  --rrui-input-field-label-color-focus: var(--rrui-accent-color);
  --rrui-input-field-label-color-error: var(--rrui-error-color);
  /* `<Select/>` toggler color. */
  --rrui-select-toggler-text-color-active: var(--rrui-accent-color);
  --rrui-select-toggler-text-color-empty: var(--rrui-gray-color);
  /* `<Select/>` */
  --rrui-list-vertical-padding: var(--rrui-unit);
  --rrui-list-item-height: calc(var(--rrui-unit) * 4);
  --rrui-list-item-side-padding: calc(var(--rrui-unit) * 1.5);
  --rrui-list-item-icon-margin: var(--rrui-unit);
  /* `<Select/>` options colors. */
  --rrui-list-item-background-color: var(--rrui-card-background-color);
  --rrui-list-item-text-color: var(--rrui-card-text-color);
  /* `<Select/>` options hover/focused colors. */
  --rrui-list-item-background-color-focus: var(--rrui-gray-color-lightest);
  --rrui-list-item-text-color-focus: var(--rrui-list-item-text-color);
  /* `<Select/>`'s currently selected option colors. */
  --rrui-list-item-background-color-selected: var(--rrui-accent-color);
  --rrui-list-item-text-color-selected: var(--rrui-white-color);
  /* `<Checkbox/>` size. */
  --rrui-checkbox-size: 0.9em;
  /* `<Checkbox/>` colors. */
  --rrui-checkbox-stroke-color: var(--rrui-black-color);
  --rrui-checkbox-fill-color: var(--rrui-input-field-background-color);
  --rrui-checkbox-fill-color-error: var(--rrui-input-field-background-color-error);
  --rrui-checkbox-color-focus: var(--rrui-accent-color);
  --rrui-checkbox-color-checked: var(--rrui-accent-color);
  --rrui-checkbox-color-checked-focus: var(--rrui-checkbox-color-focus);
  --rrui-checkbox-color-active: var(--rrui-accent-color-light);
  --rrui-checkbox-focus-ring-size: 250%;
  --rrui-checkbox-focus-ring-background-color: transparent;
  --rrui-checkbox-focus-ring-background-color-active: transparent;
  /* `<Modal/>`. */
  --rrui-modal-padding: calc(var(--rrui-unit) * 3);
  /* `<Modal/>` colors. */
  --rrui-modal-background-color: var(--rrui-card-background-color);
  --rrui-modal-text-color: var(--rrui-card-text-color);
  --rrui-modal-close-button-background-color-focus: var(--rrui-gray-color-lightest);
  /* `<SlideOutMenu/>` colors. */
  --rrui-slide-out-menu-background-color: var(--rrui-black-color);
  --rrui-slide-out-menu-text-color: var(--rrui-white-color);
  /* `<SlideOutMenu/>` colors :active. */
  --rrui-slide-out-menu-background-color-active: var(--rrui-accent-color);
  --rrui-slide-out-menu-text-color-active: var(--rrui-slide-out-menu-text-color);
  /* `<Snackbar/>` colors. */
  --rrui-snackbar-background-color: var(--rrui-black-color);
  --rrui-snackbar-text-color: var(--rrui-white-color);
  /* `<Switch/>` colors. */
  --rrui-switch-focus-outline-shadow: 0 0 0 0 transparent;
  --rrui-switch-knob-border-color-focus: var(--rrui-accent-color);
  --rrui-switch-background-color: var(--rrui-checkbox-fill-color);
  --rrui-switch-accent-color: var(--rrui-checkbox-color-checked);
  /* `<Switcher/>` colors. */
  --rrui-switcher-background-color: transparent;
  --rrui-switcher-text-color: var(--rrui-accent-color);
  /* `<Switcher/>` colors :active. */
  --rrui-switcher-background-color-active: var(--rrui-accent-color-light);
  --rrui-switcher-text-color-active: var(--rrui-white-color);
  /* `<Switcher/>` colors selected. */
  --rrui-switcher-background-color-selected: var(--rrui-accent-color);
  --rrui-switcher-text-color-selected: var(--rrui-white-color);
  /* `<Tooltip/>` colors. */
  --rrui-tooltip-background-color: var(--rrui-black-color);
  --rrui-tooltip-text-color: var(--rrui-white-color);
  /* `<ExpansionPanel/>` */
  --rrui-expansion-panel-heading-height: calc(var(--rrui-unit) * 4);
  --rrui-expansion-panel-heading-height-expanded: calc(var(--rrui-unit) * 5);
  --rrui-expansion-panel-animation-duration: 210ms;
  --rrui-expansion-panel-icon-size: var(--rrui-unit);
  --rrui-expansion-panel-side-padding: calc(var(--rrui-unit) * 2);
  --rrui-expansion-panel-content-padding-top: calc(var(--rrui-unit) * 2);
  --rrui-expansion-panel-content-padding-bottom: calc(var(--rrui-unit) * 2);
  --rrui-expansion-panel-border-color-focus: var(--rrui-accent-color);
  --rrui-expansion-panel-border-color-active: var(--rrui-accent-color-light);
  /* z-index */
  --rrui-z-index-overlay: 100;
  --rrui-z-index-above-overlay: 101; }

/* Developers should define `:focus` styles for focusable controls. */
/* Didn't add `:focus` specifier for lesser CSS rule priority. */
.rrui__outline:not(.rrui__outline--default) {
  outline: none; }

/* Reset the default `<button/>` styles */
.rrui__button-reset {
  margin: 0;
  padding: 0;
  white-space: nowrap;
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  user-select: none;
  /* box-sizing  : border-box; */ }

/* This CSS class can be used on its own in applications. */
/* Because of that, `outline: none` is added here too. */
/* Developers should define `:focus` styles for buttons. */
/* Didn't add `:focus` specifier for lesser CSS rule priority. */
.rrui__button-reset:not(.rrui__outline--default) {
  outline: none; }

.rrui__button-reset--link:hover {
  text-decoration: none; }

.rrui__activity-indicator {
  position: relative;
  width: var(--rrui-input-height);
  height: var(--rrui-input-height); }

.rrui__activity-indicator__arc {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  animation: rrui__activity-indicator 1.2s cubic-bezier(0.5, 0.15, 0.5, 0.85) infinite;
  border-color: currentColor transparent transparent transparent; }

.rrui__activity-indicator__arc:nth-child(1) {
  animation-delay: -0.45s; }

.rrui__activity-indicator__arc:nth-child(2) {
  animation-delay: -0.3s; }

.rrui__activity-indicator__arc:nth-child(3) {
  animation-delay: -0.15s; }

@keyframes rrui__activity-indicator {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.rrui__autocomplete__input {
  height: 100%;
  box-sizing: border-box; }

/* Stretches the inputs to full column width by default. */
/* Because everyone uses CSS grids today. */
.rrui__autocomplete__input {
  width: 100%; }

.rrui__autocomplete .rrui__ellipsis {
  position: absolute;
  right: calc(var(--rrui-unit) / 3);
  top: calc(50% - var(--rrui-unit) / 6);
  pointer-events: none; }

.rrui__autocomplete__input {
  padding-right: calc(var(--rrui-unit) * 2.5); }

.rrui__autocomplete .rrui__options-list {
  /*
    `<Select/>` options text should be at least fully visible.
    At the same time `<Select/>` options should stretch themselves
    horizontally to the width of the containing column (when using grid layout).
    */
  width: calc(100% + (var(--rrui-list-item-side-padding) - var(--rrui-input-field-side-padding)) * 2);
  /* Makes `border-bottom` visible on an iPhone */
  margin-top: 1px; }

.rrui__autocomplete--compact {
  /* Reverts `width: 100%` set at the top of this file */
  width: auto;
  /* If it's `compact` then it's most likely inlined */
  display: inline-block; }

.rrui__autocomplete--compact .rrui__expandable-list {
  width: auto; }

.rrui__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: var(--rrui-input-height);
  padding-left: var(--rrui-button-side-padding);
  padding-right: var(--rrui-button-side-padding);
  box-sizing: border-box;
  transition: background-color 30ms ease-out, color 30ms ease-out, border-color 30ms ease-out;
  background-color: var(--rrui-button-background-color);
  color: var(--rrui-button-text-color);
  border-radius: var(--rrui-button-border-radius); }

.rrui__button:active {
  background-color: var(--rrui-button-background-color-active);
  color: var(--rrui-button-text-color-active); }

.rrui__button--busy,
.rrui__button--disabled {
  /* "pointer-events" prevents "cursor: wait" from working */
  pointer-events: none; }

.rrui__button--stretch {
  display: flex;
  width: 100%; }

.rrui__button--link:hover {
  text-decoration: none; }

.rrui__button__busy {
  display: block;
  position: absolute;
  left: var(--rrui-button-side-padding);
  bottom: calc(50% - 0.7em);
  width: calc(100% - 2 * var(--rrui-button-side-padding));
  height: 0.1em;
  overflow: hidden;
  opacity: 0;
  transition: opacity 300ms ease-out 0ms; }

.rrui__button__busy--after-show {
  opacity: 1;
  transition: opacity 700ms ease-out 200ms; }

.rrui__button__busy:before {
  display: block;
  position: absolute;
  content: "";
  left: -40%;
  width: 40%;
  height: 100%;
  background-color: currentColor;
  animation: rrui__button__busy 2s ease-in infinite; }

/* Based on "CSS indeterminate progress" example. */
/* https://codepen.io/brunjo/pen/XJmbNz */
@keyframes rrui__button__busy {
  from {
    left: -40%;
    width: 40%; }
  50% {
    width: 70%; }
  70% {
    width: 85%; }
  80% {
    left: 30%;
    width: 100%; }
  95% {
    left: 60%; }
  to {
    left: 100%; } }

/* http://tympanus.net/codrops/2013/10/15/animated-checkboxes-and-radio-buttons-with-svg/ */
.rrui__checkbox .rrui__input {
  display: inline-flex;
  align-items: center; }

.rrui__checkbox .rrui__input--multiline {
  /* IE and Edge have a bug with `inline-flex`:
       the multiline content shrinks in width. */
  display: flex;
  align-items: flex-start; }

.rrui__checkbox__label {
  display: inline-block;
  /* Using padding instead of margin here to make the gap clickable. */
  padding-left: var(--rrui-control-label-margin);
  vertical-align: bottom;
  color: inherit;
  user-select: none; }

.rrui__checkbox__label:not(.rrui__checkbox__label--multiline) {
  white-space: nowrap; }

.rrui__checkbox__label--multiline {
  /* A fix for IE 11 to force it wrap `<label/>` text */
  flex: 1 0 0; }

.rrui__checkbox--multiline .rrui__checkbox__checkbox {
  margin-top: 0.12em; }

.rrui__checkbox__input,
.rrui__checkbox__label {
  cursor: pointer; }

.rrui__checkbox--disabled {
  pointer-events: none; }

.rrui__checkbox__checkbox {
  position: relative;
  z-index: 0;
  flex-shrink: 0;
  width: var(--rrui-checkbox-size);
  height: var(--rrui-checkbox-size);
  color: var(--rrui-checkbox-stroke-color); }

.rrui__checkbox__input {
  position: absolute;
  z-index: 3;
  left: -40%;
  top: -40%;
  width: 180%;
  height: 180%;
  margin: 0;
  opacity: 0; }

.rrui__checkbox__box {
  display: block;
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  fill: currentColor;
  user-select: none;
  pointer-events: none; }

/* Checkbox color (:checked) */
.rrui__checkbox__box--checked {
  color: var(--rrui-checkbox-color-checked); }

/* Checkbox color (:focus) */
.rrui__checkbox__input:focus + .rrui__checkbox__box {
  color: var(--rrui-checkbox-color-focus); }

/* Checkbox color (:focus) (:checked) */
.rrui__checkbox__input:focus + .rrui__checkbox__box--checked {
  color: var(--rrui-checkbox-color-checked-focus); }

/* Checkbox color (:checked / :active) */
.rrui__checkbox__input:active + .rrui__checkbox__box {
  color: var(--rrui-checkbox-color-active); }

.rrui__checkbox__box-background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: var(--rrui-checkbox-fill-color);
  border-radius: 10%;
  transform: scale(0.9); }

.rrui__checkbox--invalid .rrui__checkbox__box-background {
  background-color: var(--rrui-input-field-background-color-error); }

/* Checkbox color (:invalid) */
.rrui__checkbox--invalid .rrui__checkbox__box {
  color: var(--rrui-input-field-border-color-error); }

/* Focus ring. */
.rrui__checkbox__focus-ring {
  display: block;
  position: absolute;
  left: calc(-1 * (var(--rrui-checkbox-focus-ring-size) - 100%) / 2);
  top: calc(-1 * (var(--rrui-checkbox-focus-ring-size) - 100%) / 2);
  width: var(--rrui-checkbox-focus-ring-size);
  height: var(--rrui-checkbox-focus-ring-size);
  border-radius: 50%;
  z-index: 0; }

.rrui__checkbox__input:focus + .rrui__checkbox__box + .rrui__checkbox__box-background + .rrui__checkbox__focus-ring {
  background-color: var(--rrui-checkbox-focus-ring-background-color); }

.rrui__checkbox__input:active + .rrui__checkbox__box + .rrui__checkbox__box-background + .rrui__checkbox__focus-ring {
  background-color: var(--rrui-checkbox-focus-ring-background-color-active); }

/*
.rrui__close--top-right
{
    position : fixed;
    top      : var(--rrui-close-margin);
    right    : var(--rrui-close-margin);
    z-index  : 1;
}
*/
.rrui__close--bottom-right {
  position: fixed;
  bottom: var(--rrui-close-margin);
  right: var(--rrui-close-margin);
  z-index: 1; }

.rrui__close__icon {
  width: var(--rrui-close-size);
  height: var(--rrui-close-size);
  box-sizing: content-box; }

.rrui__close:active .rrui__close__icon-path,
.rrui__button:active .rrui__close__icon-path {
  color: var(--rrui-accent-color); }

.rrui__close__icon-path {
  color: var(--rrui-black-color);
  stroke: currentColor; }

.rrui__close {
  padding: calc(var(--rrui-close-size) / 2);
  margin: calc(var(--rrui-close-size) * -1 / 2); }

/* DayPicker styles (version 7.1.10) */
.DayPicker {
  user-select: none; }

.DayPicker-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  padding: calc(var(--rrui-unit) * 2);
  border-bottom: 0.1em solid transparent; }

.DayPicker-wrapper:focus {
  border-bottom-color: var(--rrui-input-field-border-color-focus); }

.DayPicker-NavButton {
  position: absolute;
  top: calc(var(--rrui-unit) * 2 + 0.25em);
  cursor: pointer;
  width: 1.5em;
  height: 1.5em; }

.DayPicker-NavButton--prev {
  left: calc(var(--rrui-unit) * 2 + 0.25em);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' style='fill: currentColor; color: %2303b2cb;'%3E%3Cpath d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z'%3E%3C/path%3E%3C/svg%3E"); }

.DayPicker-NavButton--next {
  right: calc(var(--rrui-unit) * 2 + 0.25em);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' style='fill: currentColor; color: %2303b2cb;'%3E%3Cpath d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'%3E%3C/path%3E%3C/svg%3E"); }

.DayPicker-NavButton--prev:focus,
.DayPicker-NavButton--next:focus {
  background-color: #f7f7f7;
  border-radius: 50%; }

.DayPicker-NavButton--interactionDisabled {
  display: none; }

.DayPicker-Caption {
  /* iPhone moves <select/>s around the screen on focus when using `display: table-caption`. */
  /* display: table-caption; */
  text-align: center;
  margin-bottom: 1em; }

.DayPicker-Weekdays {
  margin-top: 1em;
  display: table-header-group; }

.DayPicker-WeekdaysRow {
  display: table-row; }

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  font-size: 0.875em;
  text-align: center;
  color: var(--rrui-gray-color); }

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none; }

.DayPicker-Body {
  display: table-row-group; }

.DayPicker-Week {
  display: table-row; }

.DayPicker-Day {
  display: table-cell;
  padding: 0.5em;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  border-radius: 50%; }

.DayPicker-Day:focus {
  background-color: #f7f7f7; }

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default; }

/* Default modifiers */
.DayPicker-Day--today:not(.DayPicker-Day--selected):not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  /*
    color: var(--rrui-accent-color);
    font-weight: 700;
    */
  /* border: 1px solid var(--rrui-gray-color-lightest); */
  /*
    border-radius: 50%;
    box-shadow: 0 0 1px var(--rrui-black-color);
    */
  color: #d0021b;
  font-weight: 700; }

.DayPicker-Day--outside {
  cursor: default;
  color: var(--rrui-gray-color); }

.DayPicker-Day--disabled {
  color: var(--rrui-gray-color-light);
  cursor: default; }

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  color: var(--rrui-white-color);
  background-color: var(--rrui-accent-color); }

/* .DayPicker:not(.DayPicker--interactionDisabled) ... */
.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: var(--rrui-list-item-background-color-focus);
  color: var(--rrui-list-item-text-color-focus); }

.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):active {
  color: var(--rrui-white-color);
  background-color: var(--rrui-accent-color-light); }

/* =============================== */
/* Custom `react-daypicker` styles */
/* =============================== */
.DayPicker-Months {
  margin-top: 0.5em; }

.DayPicker-Month {
  margin-top: 0; }

.DayPicker-MonthSelect {
  margin-right: 0.3em; }

.DayPicker-MonthSelect,
.DayPicker-YearSelect {
  display: inline-block;
  position: relative; }

/* `:focus` styles are implemented using
   `background-color` / `border-color`
   instead of a standard `outline`. */
/* Didn't add `:focus` specifier for lesser CSS rule priority. */
/* ------------------------------------ */
/* `.DayPicker-wrapper` is a `<div/>` wrapping the calendar.
   It gets focused upon expanding which causes an outline.
   That outline can be safely removed because it doesn't aid accesiblity. */
.rrui__date-picker:not(.rrui__outline--default) .DayPicker-wrapper,
.rrui__date-picker:not(.rrui__outline--default) .DayPicker-Day,
.rrui__date-picker:not(.rrui__outline--default) .DayPicker-NavButton {
  outline: none; }

/* https://github.com/gpbl/react-day-picker/issues/708 */
/*
.DayPicker-NavButton:active
{
    color : var(--rrui-accent-color-light);
}
*/
/* ----------------------------------------------------- */
/* -                  `<DatePicker/>`                  - */
/* ----------------------------------------------------- */
.rrui__date-picker {
  position: relative; }

.rrui__date-picker .rrui__expandable {
  min-width: 100%;
  background-color: var(--rrui-list-item-background-color);
  color: var(--rrui-list-item-text-color); }

.rrui__date-picker .rrui__expandable__content {
  /* For compatibility with older versions. */
  /* Deprecated. Will be removed in versions > 0.14. */
  padding: 0; }

.rrui__date-picker__calendar abbr {
  text-decoration: none; }

.rrui__date-picker__input-overlay {
  /*
    Set `display` to `block` for fullscreen `.rrui__expandable` design.
    This works around the mobile keyboard appearing from the bottom.
    display  : block;
    */
  display: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  /* `z-index` could potentially cause a bug
       when the overlay would be above other content on a page
       resulting in it capturing events like "mousedown" or "click". */
  /* z-index  : 1; */
  outline: none; }

.rrui__date-picker__close {
  /*
    Set `display` to `block` for fullscreen `.rrui__expandable` design.
    display      : block;
    */
  display: none; }

.rrui__date-picker:focus {
  display: none; }

.rrui__date-picker__icon {
  position: absolute;
  top: calc((var(--rrui-input-height) - var(--rrui-unit) * 1.5) / 2);
  right: calc(var(--rrui-unit) / 2);
  width: calc(var(--rrui-unit) * 1.5);
  height: calc(var(--rrui-unit) * 1.5);
  opacity: 0.5;
  color: var(--rrui-black-color);
  /* `z-index` could potentially cause a bug
       when the button would be above other content on a page
       resulting in it capturing events like "mousedown" or "click". */
  /* z-index  : 1; */ }

.rrui__date-picker__icon--hidden {
  display: none; }

/*
// Adding `:focus` style for date picker icon is not required
// because it's not tabbable (`tabIndex={-1}`).
.rrui__date-picker__icon:focus,
*/
.rrui__date-picker .rrui__expanded--expanded .rrui__date-picker__icon {
  opacity: 1;
  color: var(--rrui-accent-color); }

.rrui__date-picker__icon:focus {
  opacity: 1;
  color: var(--rrui-accent-color); }

.rrui__date-picker__icon:active {
  opacity: 1;
  color: var(--rrui-accent-color-light); }

.DayPicker-YearSelect select:focus + button .rrui__select__arrow,
.DayPicker-MonthSelect select:focus + button .rrui__select__arrow {
  color: var(--rrui-select-toggler-text-color-active); }

.rrui__divider {
  line-height: 0;
  font-size: 0; }

.rrui__divider__line {
  width: 100%;
  height: 1px;
  margin-bottom: -1px;
  background-color: rgba(0, 0, 0, 0.16);
  border: 0; }

.rrui__ellipsis {
  width: calc(var(--rrui-unit) * 1.5);
  opacity: 0.7; }

.rrui__ellipsis__size {
  position: relative;
  width: 100%;
  padding-bottom: 22%; }

.rrui__ellipsis__dots {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%; }

.rrui__ellipsis__dot {
  width: 22%;
  height: 100%;
  margin-right: 16.5%;
  border-radius: 50%;
  background-color: currentColor;
  opacity: 0.4; }

.rrui__ellipsis__dot--1 {
  animation: 1.1s linear 0s infinite rrui__ellipsis; }

.rrui__ellipsis__dot--2 {
  animation: 1.1s linear 0.36666667s infinite rrui__ellipsis; }

.rrui__ellipsis__dot--3 {
  margin-right: 0;
  animation: 1.1s linear 0.73333333s infinite rrui__ellipsis; }

@keyframes rrui__ellipsis {
  25% {
    opacity: 1; }
  50% {
    opacity: 0.4; } }

.rrui__expandable {
  opacity: 0;
  pointer-events: none;
  transform: scaleY(0.8);
  transform-origin: 50% top 0px;
  /* 250ms - too slow for short lists */
  transition: all 150ms ease-out;
  /* prevent white background showing through the transparent border */
  /* http://stackoverflow.com/questions/7473200/transparent-border-expands-background-color-image */
  background-clip: padding-box;
  -webkit-background-clip: padding-box; }

.rrui__expandable--expanded {
  opacity: 1;
  pointer-events: auto;
  transform: scaleY(1);
  transform-origin: 50% top 0px;
  transition: all 150ms cubic-bezier(0.23, 1, 0.32, 1); }

.rrui__expandable--overlay {
  position: absolute;
  z-index: 1; }

.rrui__expandable__content {
  /*
    The inner block's `opacity` is animated too
    (it fades in and out by itself too).
    This is done to fade out the inner block when the dropdown is collapsed
    therefore hiding an otherwise bizarre and unwanted visual clutter
    */
  opacity: 0;
  /* Mitigates inner content overlapping with itself creating a feeling of a mess */
  transition: opacity 100ms ease-out; }

.rrui__expandable__content--expanded {
  opacity: 1; }

.rrui__expandable--left-aligned {
  left: 0; }

.rrui__expandable--right-aligned {
  right: 0; }

.rrui__expandable--downward {
  /* When an html page is overflown by a long list
       then this bottom margin helps to see that
       there's no more options left out being clipped or something */
  margin-bottom: 1em; }

.rrui__expandable--upward {
  bottom: 100%;
  /* When an html page is overflown by a long list
       then this bottom margin helps to see that
       there's no more options left out being clipped or something */
  margin-top: 1em;
  transform-origin: 50% bottom 0px; }

.rrui__expandable__close {
  /*
    Set `display` to `block` for fullscreen `.rrui__expandable` design.
    display : block;
    */
  display: none; }

.rrui__expandable-list--left-aligned {
  left: 0; }

.rrui__expandable-list--right-aligned {
  right: 0; }

.rrui__options-list--left-aligned {
  left: calc((var(--rrui-list-item-side-padding) - var(--rrui-input-field-side-padding)) * -1); }

.rrui__options-list--right-aligned {
  right: calc((var(--rrui-list-item-side-padding) - var(--rrui-input-field-side-padding)) * -1); }

/* Styles are from Material UI. */
/* https://material-ui.com/demos/expansion-panels/ */
.rrui__expansion-panel {
  background-color: var(--rrui-white-color); }

.rrui__expansion-panel--expanded {
  /* margin: 16px 0; */ }

/*
.rrui__expansion-panel:before
{
    top: -1px;
    left: 0;
    right: 0;
    height: 1px;
    content: "";
    opacity: 1;
    position: absolute;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: ;
}

.rrui__expansion-panel--expanded:before
{
    opacity: 0;
}
*/
.rrui__expansion-panel__header {
  position: relative;
  width: 100%;
  height: var(--rrui-expansion-panel-heading-height);
  transition: height var(--rrui-expansion-panel-animation-duration) cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: normal;
  text-align: left; }

.rrui__expansion-panel__header--expanded {
  height: var(--rrui-expansion-panel-heading-height-expanded); }

.rrui__expansion-panel__header--toggle-icon-end {
  background-color: var(--rrui-gray-color-lightest); }

.rrui__expansion-panel__header--toggle-icon-end:focus {
  box-shadow: inset 0 0 0 1px var(--rrui-gray-color-light); }

/* CSS select specificity is to support <Collapsible/>s inside <Collapsible/>s. */
.rrui__expansion-panel__header--toggle-icon-start:focus > .rrui__expansion-panel__heading {
  padding-bottom: 0.15em;
  border-bottom: 1px solid var(--rrui-expansion-panel-border-color-focus); }

/* CSS select specificity is to support <Collapsible/>s inside <Collapsible/>s. */
.rrui__expansion-panel__header--toggle-icon-start:active > .rrui__expansion-panel__heading {
  border-bottom: 1px solid var(--rrui-expansion-panel-border-color-active); }

.rrui__expansion-panel__header--toggle-icon-start.rrui__expansion-panel__header--expanded {
  height: var(--rrui-expansion-panel-heading-height); }

.rrui__expansion-panel__content-wrapper {
  overflow: hidden;
  transition: height var(--rrui-expansion-panel-animation-duration) cubic-bezier(0.4, 0, 0.2, 1); }

.rrui__expansion-panel__header,
.rrui__expansion-panel__content {
  padding-left: var(--rrui-expansion-panel-side-padding);
  padding-right: var(--rrui-expansion-panel-side-padding); }

.rrui__expansion-panel__content {
  padding-top: var(--rrui-expansion-panel-content-padding-top);
  padding-bottom: var(--rrui-expansion-panel-content-padding-bottom); }

.rrui__expansion-panel__icon {
  position: absolute;
  top: 50%;
  width: var(--rrui-expansion-panel-icon-size);
  transition: transform var(--rrui-expansion-panel-animation-duration) cubic-bezier(0.4, 0, 0.2, 1); }

.rrui__expansion-panel__icon--end {
  right: var(--rrui-expansion-panel-side-padding);
  transform: translateY(-50%) rotate(0deg); }

.rrui__expansion-panel__icon--expanded.rrui__expansion-panel__icon--end {
  transform: translateY(-50%) rotate(180deg); }

.rrui__expansion-panel__icon--start {
  left: 0;
  margin-right: var(--rrui-unit);
  transform: translateY(-50%) rotate(-90deg); }

.rrui__expansion-panel__icon--expanded.rrui__expansion-panel__icon--start {
  transform: translateY(-50%) rotate(0deg); }

.rrui__file-upload__area {
  cursor: pointer; }

.rrui__file-upload__area--disabled {
  cursor: default; }

.rrui__form {
  /* Chrome adds margin-bottom for all <form/>s for some weird reason */
  margin-bottom: 0; }

.rrui__form__actions {
  margin-top: calc(var(--rrui-unit) * 4);
  /* Right alignment */
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }

.rrui__form__actions--left-right {
  justify-content: space-between; }

.rrui__form__actions .rrui__button {
  margin-right: calc(4 * var(--rrui-unit)); }

.rrui__form__actions .rrui__button:last-child {
  margin-right: 0; }

.rrui__form__field {
  margin-bottom: calc(var(--rrui-unit) * 2); }

.rrui__form__error:empty {
  display: none; }

.rrui__input {
  /* `position: relative` is for the floating label absolute positioning */
  position: relative; }

.rrui__input,
.rrui__input-label {
  height: var(--rrui-input-height);
  font-size: var(--rrui-input-field-font-size); }

/* `.rrui__input:not(.rrui__input--multiline)`
   would result in issues with this CSS selector being
   too specific and therefore taking higher priority */
.rrui__input--multiline {
  height: auto; }

/*
For some reason web browsers size `<input/>`s to be wider
than 100% of the parent if the parent is small enough.
*/
.rrui__input-element {
  max-width: 100%; }

.rrui__input-error {
  margin-top: var(--rrui-unit);
  color: var(--rrui-error-color); }

.rrui__input-field {
  border-radius: 0;
  /* Some web browsers add <input/> padding by default */
  padding: 0;
  appearance: none;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit; }

/* Overrides Chrome autofill yellow background color. */
.rrui__input-field:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--rrui-input-field-background-color) inset; }

/* Overrides Chrome autofill yellow background color (when `<TextInput/>` is focused). */
.rrui__input-field:focus:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--rrui-input-field-background-color-focus) inset; }

/* Overrides Chrome autofill yellow background color (when `<TextInput/>` is invalid). */
.rrui__input-field--invalid:-webkit-autofill,
.rrui__input-field--invalid:focus:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--rrui-input-field-background-color-error) inset; }

/* For `<TextInput/>` and alike. */
.rrui__input-field,
.rrui__select__button {
  /* Stretches the inputs to full column width by default. */
  /* Because everyone uses CSS grids today. */
  width: 100%;
  padding-left: var(--rrui-input-field-side-padding);
  padding-right: var(--rrui-input-field-side-padding);
  background-color: var(--rrui-input-field-background-color);
  border-radius: var(--rrui-input-field-border-radius); }

/* For `<TextInput/>` and alike. */
.rrui__input-field,
.rrui__select__button,
.rrui__select__native {
  border-width: 0;
  border-bottom-width: var(--rrui-input-field-border-width);
  border-style: solid;
  border-color: var(--rrui-input-field-border-color);
  transition: var(--rrui-input-field-transition); }

/* For `<TextInput/>` and alike. */
.rrui__input-field:focus,
.rrui__select__button:focus,
.rrui__select__native:focus + .rrui__select__button,
.rrui__select__native:focus {
  border-color: var(--rrui-input-field-border-color-focus);
  background-color: var(--rrui-input-field-background-color-focus); }

/* For `<TextInput/>` and alike. */
.rrui__input-field--invalid,
.rrui__input-field--invalid:focus,
.rrui__select__button--invalid,
.rrui__select__button--invalid:focus,
.rrui__select__native--invalid:focus + .rrui__select__button,
.rrui__select__native--invalid,
.rrui__select__native--invalid:focus {
  border-color: var(--rrui-input-field-border-color-error);
  background-color: var(--rrui-input-field-background-color-error); }

.rrui__input-field {
  height: 100%;
  font-size: inherit;
  box-sizing: border-box; }

/* Resets height back to 100% for multiline text input */
.rrui__input-field--multiline {
  height: auto;
  resize: none; }

/* For `<TextInput/>` and alike. */
.rrui__input-field--disabled,
.rrui__select__button--disabled {
  /* "pointer-events" prevents "cursor: wait" from working. */
  pointer-events: none;
  /* cursor : default; */
  background-color: var(--rrui-input-field-background-color-disabled); }

.rrui__input-label {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: inherit;
  user-select: none;
  pointer-events: none;
  /*
    When changing this label color
    one could also change "input" and "textarea" placeholder color
    if using `placeholder` property.
    Changing `:placeholder` color is not widely supported though.
    */
  color: var(--rrui-input-field-label-color);
  transform: scale(var(--rrui-input-label-focus-scale)) translateY(var(--rrui-input-label-focus-top-offset));
  transform-origin: left top;
  transition: color 0.1s ease-out, transform 450ms cubic-bezier(0.23, 1, 0.32, 1); }

/* Focused input label. */
/* For `<Select native={false}/>` (default). */
.rrui__select__native:focus + .rrui__select__button + .rrui__input-label,
.rrui__select__native:focus + .rrui__input-label,
.rrui__input-element:focus + .rrui__input-label {
  color: var(--rrui-input-field-label-color-focus); }

.rrui__list {
  margin: 0;
  padding-left: 0;
  padding-top: var(--rrui-list-vertical-padding);
  padding-bottom: var(--rrui-list-vertical-padding);
  overflow-y: auto;
  background-color: var(--rrui-list-item-background-color);
  color: var(--rrui-list-item-text-color);
  list-style-type: none;
  overflow-x: hidden;
  box-sizing: border-box; }

.rrui__list__item {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  /*
    This side padding is also connected to
    `.rrui__select__options--left-aligned`'s `left`,
    `.rrui__select__options--right-aligned`'s `right`
    and `.rrui__select__options`'s `min-width`.
    */
  padding-left: var(--rrui-list-item-side-padding);
  padding-right: var(--rrui-list-item-side-padding);
  color: inherit;
  box-sizing: border-box;
  text-align: left;
  white-space: nowrap; }

.rrui__list__item:hover {
  background-color: var(--rrui-list-item-background-color-focus);
  color: var(--rrui-list-item-text-color-focus); }

.rrui__list__item:active {
  background-color: var(--rrui-list-item-background-color-selected);
  color: var(--rrui-list-item-text-color-selected); }

.rrui__list__item--button {
  height: var(--rrui-list-item-height); }

.rrui__list__item--divider {
  padding-left: 0;
  padding-right: 0;
  padding-top: var(--rrui-list-vertical-padding);
  padding-bottom: var(--rrui-list-vertical-padding);
  pointer-events: none; }

.rrui__list__item--focus {
  background-color: var(--rrui-list-item-background-color-focus);
  color: var(--rrui-list-item-text-color-focus); }

.rrui__list__item--selected,
.rrui__list__item--selected:hover {
  background-color: var(--rrui-list-item-background-color-selected);
  color: var(--rrui-list-item-text-color-selected); }

.rrui__list__item-icon {
  margin-right: var(--rrui-list-item-icon-margin);
  flex-shrink: 0; }

.rrui__list__item--disabled {
  /* "pointer-events" prevents "cursor: wait" from working */
  pointer-events: none; }

.rrui__list__items--scroll {
  /* https://css-tricks.com/snippets/css/momentum-scrolling-on-ios-overflow-elements/ */
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; }

.rrui__menu {
  /* Usually menu toggler is not 100% wide, so it makes sense. */
  display: inline-block;
  position: relative; }

.rrui__menu .rrui__expandable-list {
  width: auto; }

.rrui__menu .rrui__list__item {
  padding-left: var(--rrui-collapsible-menu-item-side-padding);
  padding-right: var(--rrui-collapsible-menu-item-side-padding); }

.rrui__menu .rrui__list__item--divider {
  padding-left: 0;
  padding-right: 0; }

.rrui__menu-button {
  position: relative;
  display: inline-block;
  width: calc(var(--rrui-input-height) + var(--rrui-unit));
  height: calc(var(--rrui-input-height) + var(--rrui-unit));
  padding: calc(var(--rrui-unit) / 2);
  margin: calc(-1 * var(--rrui-unit) / 2);
  line-height: 0;
  color: var(--rrui-black-color);
  /* This CSS rule can be removed if .rrui__button-reset gets it. */
  box-sizing: border-box; }

/*
.rrui__menu-button:active
{
    color : var(--rrui-accent-color);
}
*/
/* https://codepen.io/bennettfeely/pen/twbyA */
.rrui__menu-icon {
  position: relative;
  width: 100%;
  height: 100%; }

.rrui__menu-icon__bar {
  position: absolute;
  left: 0;
  top: calc((100% - 6%) / 2);
  width: 100%;
  height: 6%;
  background-color: currentColor; }

.rrui__menu-icon__bar--transition {
  transition: transform var(--rrui-menu-button-animation-duration), opacity var(--rrui-menu-button-animation-duration); }

.rrui__menu-icon__bar:nth-child(1) {
  transform: translateY(-450%); }

.rrui__menu-icon__bar:nth-child(3) {
  transform: translateY(450%); }

/*
.rrui__menu-icon:hover .rrui__menu-icon__bar:nth-child(1)
{
    transform : translateY(-550%);
}

.rrui__menu-icon:hover .rrui__menu-icon__bar:nth-child(3)
{
    transform : translateY(550%);
}
*/
.rrui__menu-icon__bar--expanded:nth-child(2) {
  opacity: 0;
  transform: scale(0.9); }

.rrui__menu-icon__bar--expanded:nth-child(1) {
  transform: rotate(-45deg) scale(0.9); }

.rrui__menu-icon__bar--expanded:nth-child(3) {
  transform: rotate(45deg) scale(0.9); }

/*===========================================================
= `react-modal`                                             =
===========================================================*/
/*
when changing css transition times here
also change `default_close_timeout` in ./Modal.js accordingly
*/
/* Could have used `opacity` transition on `.ReactModal__Overlay`
   but it introduced a bug in Chrome: the fixed Overlay shriked vertically
   while having its opacity animated at the same time as the Content being scaled */
.ReactModal__Overlay {
  background-color: transparent;
  /* "auto" introduces a vertical scrollbar when "bouncing" (animation) */
  /* overflow: auto; */
  overflow: hidden; }

.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.25); }

/* This rule is required for closing animation */
.ReactModal__Overlay--before-close {
  background-color: transparent; }

.ReactModal__Overlay--after-open {
  transition: background-color 300ms ease-out; }

.ReactModal__Overlay--before-close {
  transition: background-color 100ms ease-out; }

.rrui__modal__contents {
  opacity: 0;
  /*
    Scaling `<Modal/>` down on appear looks cool
    but it also can cause scrollbars to appear
    due to overflow which is a weird `scale()` behaviour.
    Those scrollbars alter window width resulting in
    `<Modal/>` positioning glitches (while centering itself).
    Therefore not using `scale()`s above `1`, only downscaling.
    transform  : scale(1.07);
    */
  transform: scale(0.95);
  transition: transform 100ms ease-out, opacity 100ms ease-out; }

.ReactModal__Content--after-open .rrui__modal__contents {
  opacity: 1;
  transform: none; }

/* This rule is required for closing animation */
.ReactModal__Content--before-close .rrui__modal__contents {
  opacity: 0;
  transform: scale(0.95); }

/*
The extra opacity animation on `<Modal/>` contents
is used to prevent clutter on background while the modal fades in/out.
*/
.rrui__modal__contents-body {
  opacity: 0; }

.ReactModal__Content--after-open .rrui__modal__contents-body {
  opacity: 1;
  transition: opacity 100ms ease-out; }

.ReactModal__Content--before-close .rrui__modal__contents-body {
  opacity: 0;
  transition: opacity 80ms ease-out; }

/* Hides `react-modal` scrollbar when closing the modal */
.ReactModal__Overlay--before-close {
  overflow: hidden; }

/*
While this would disable page scrolling when modal is presented,
it would introduce page width jumps.
.ReactModal__Body--open
{
    height: 100%;
    overflow: hidden;
}
*/
/*==========================================================
= `<Modal/>`                                               =
===========================================================*/
/*
Make sure to add `.rrui__fixed-full-width` CSS class
to all full-width `position: fixed` elements.
Such elements must not be `width: 100%`
but rather `width: auto` or `left: 0; right: 0;`.
*/
.rrui__fixed-full-width {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: auto; }

.rrui__modal__overlay {
  /* Horizontal centering */
  display: flex;
  justify-content: center; }

.rrui__modal__overlay--fullscreen {
  /* `display: flex` results in a weird content clipping bug in fullscreen mode
       because of `justify-content: center` on the container */
  display: block;
  background-color: var(--rrui-modal-background-color); }

.rrui__modal__overlay--hidden {
  display: none; }

.rrui__modal__overlay--busy {
  cursor: wait; }

.rrui__modal__container {
  /* Centers everything vertically */
  min-height: 100%;
  /* Using `inline-flex` here so that it doesn't
       stretch to the whole overlay width */
  display: inline-flex;
  flex-direction: column; }

.rrui__modal__container--fullscreen {
  display: flex;
  align-items: center;
  justify-content: center; }

.rrui__modal__close:focus {
  background-color: var(--rrui-modal-close-button-background-color-focus);
  border-radius: 50%; }

.rrui__modal__close--busy {
  cursor: wait; }

.rrui__modal__contents {
  /* So that modal content won't neccessarily
       stretch horizontally to the full width of the screen */
  display: inline-block;
  /* Modal content shouldn't stretch vertically */
  flex-grow: 0;
  /* Modal content will contract vertically */
  flex-shrink: 1;
  flex-basis: auto;
  border-radius: var(--rrui-border-radius-big);
  background-color: var(--rrui-modal-background-color);
  color: var(--rrui-modal-text-color); }

.rrui__modal__contents--fullscreen {
  /* Completely cover the screen */
  border-radius: 0; }

.rrui__modal__vertical-margin {
  /* Perhaps "width : 100%" was needed for it to work properly */
  width: 100%;
  /* Vertical padding won't ever shrink below the minimum size */
  flex-shrink: 0;
  flex-basis: 3%; }

.rrui__modal__vertical-margin--top {
  flex-grow: 2; }

.rrui__modal__vertical-margin--bottom {
  flex-grow: 5; }

.rrui__modal__vertical-margin--fullscreen {
  display: none; }

.rrui__modal--could-not-close-because-busy {
  animation: bounce_z 0.6s;
  transform: translate3d(0, 0, 0); }

/* https://daneden.github.io/animate.css/ */
/* https://github.com/daneden/animate.css/blob/master/source/bouncing_entrances/bounceIn.css */
@keyframes bounce_z {
  from, 25%, 47%, 72%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    transform: scale3d(1, 1, 1); }
  25% {
    transform: scale3d(0.99, 0.99, 0.99); }
  47% {
    transform: scale3d(1.01, 1.01, 1.01); }
  72% {
    transform: scale3d(0.995, 0.995, 0.995); }
  to {
    transform: scale3d(1, 1, 1); } }

.rrui__modal__content .rrui__form__error {
  margin-bottom: 0; }

/* Fullscreen `<Modal/>` animation tweaking */
.rrui__modal__overlay--fullscreen:not(.ReactModal__Overlay--after-open),
.rrui__modal__overlay--fullscreen.ReactModal__Overlay--before-close {
  background-color: transparent; }

.rrui__modal__overlay--fullscreen {
  transition: background-color 100ms ease-out; }

.rrui__modal__overlay--fullscreen .rrui__modal__contents {
  background-color: transparent; }

.rrui__modal__actions {
  display: flex;
  justify-content: flex-end; }

.rrui__modal__title,
.rrui__modal__actions,
.rrui__modal__content {
  /* Using `margin` instead of `padding` here
    to leverage the "margin collapse" CSS feature. */
  margin: var(--rrui-modal-padding); }

.rrui__modal__actions {
  margin-top: calc(var(--rrui-unit) * 2);
  margin-bottom: calc(var(--rrui-unit) * 2); }

.rrui__modal__close {
  /* Prevents `<Modal.Title/>` overlapping with `<Close/>` padding. */
  margin-left: 0;
  /* Prevents the close button from being stretched vertically. */
  line-height: 0; }

.rrui__modal__content--close-button,
.rrui__modal__title--close-button {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }

.rrui__scrollable {
  /* https://css-tricks.com/snippets/css/momentum-scrolling-on-ios-overflow-elements/ */
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; }

.rrui__select {
  /* This is for `.rrui__select__options` to be positioned
       correctly relative to the `<Select/>` itself. */
  position: relative;
  user-select: none; }

.rrui__select .rrui__options-list {
  /*
    `<Select/>` options text should be at least fully visible.
    At the same time `<Select/>` options should stretch themselves
    horizontally to the width of the containing column (when using grid layout).
    */
  width: calc(100% + (var(--rrui-list-item-side-padding) - var(--rrui-input-field-side-padding)) * 2);
  /*
    `margin-top` is constituted by:
    - `.rrui__select__options` `padding-top`.
    - `.rrui__input` `height`.
    - half of the difference between `.rrui__select__option`'s `height`/`line-height` and `.rrui__input` `height`.
    */
  margin-top: calc(-1 * (var(--rrui-list-vertical-padding) + var(--rrui-input-height) + (var(--rrui-list-item-height) - var(--rrui-input-height)) / 2)); }

.rrui__select__button {
  height: 100%;
  box-sizing: border-box;
  color: inherit;
  /* Not introducing an animation on `.rrui__select__option`
       because it would introduce a little clutter due to
       focused option animation in autocomplete mode */
  transition: color 30ms ease-out;
  /* Because Chrome adds `text-align: center` for all buttons */
  text-align: left; }

.rrui__select__button--expanded {
  color: var(--rrui-select-toggler-text-color-active); }

.rrui__select__button--empty .rrui__select__selected-label {
  color: var(--rrui-select-toggler-text-color-empty); }

/* For `<Select native={false}/>` (default). */
.rrui__select__native:active + .rrui__select__button .rrui__select__selected-label,
.rrui__select__native:active + .rrui__select__button .rrui__select__arrow,
.rrui__select__native:active + .rrui__input-label {
  color: var(--rrui-select-toggler-text-color-active); }

.rrui__select__selected-content {
  display: flex;
  align-items: center; }

.rrui__select__selected-label {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis; }

.rrui__select .rrui__ellipsis {
  position: absolute;
  right: calc(var(--rrui-unit) / 3);
  top: calc(50% - var(--rrui-unit) / 6);
  pointer-events: none; }

.rrui__select__arrow {
  width: 0;
  height: 0;
  margin-left: calc(var(--rrui-unit) * 2 / 3);
  margin-right: calc(var(--rrui-unit) * 2 / 3);
  margin-top: 0.1em;
  opacity: 0.6;
  color: var(--rrui-gray-color);
  /*transition : opacity 100ms ease-out;*/
  /* border-width: arrow_height arrow_width/2 0 arrow_width/2 */
  border-width: 0.35em 0.22em 0 0.22em;
  border-style: solid;
  border-left-color: transparent;
  border-right-color: transparent; }

.rrui__select--compact {
  /* Reverts `width: 100%` set at the top of this file */
  width: auto;
  /* If it's `compact` then it's most likely inlined */
  display: inline-block; }

.rrui__select--compact .rrui__expandable-list {
  width: auto; }

/* Stretches the inputs to full column width by default. */
/* Because everyone uses CSS grids today. */
.rrui__select__button,
.rrui__select__native {
  width: 100%; }

.rrui__select__native {
  height: 100%;
  cursor: pointer; }

/* Something from stackoverflow. */
.rrui__select__native-option--divider {
  font-size: 1px;
  background: currentColor; }

/* ChromeVox has a bug where `opacity: 0` elements are ignored. */
/* https://github.com/michalsnik/aos/issues/397 */
/* opacity  : 0.001; */
.rrui__select__native--overlay {
  position: absolute;
  /* `z-index` caused a bug when select `<button/>`
        would be above other content on a page.
        For example, when there were two `<Select/>`s
        one below another then mouse down events
        from the options list of the top select
        would "go through" to the bottom select
        expanding it. */
  /* z-index  : 1; */
  opacity: 0; }

.rrui__select__toggler {
  display: inline-block; }

.rrui__shadow {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }

.rrui__slideout-menu {
  position: fixed;
  z-index: var(--rrui-z-index-overlay);
  padding-top: calc(var(--rrui-list-vertical-padding) * 2.5);
  padding-bottom: calc(var(--rrui-list-vertical-padding) * 2.5);
  /* `box-sizing` is for padding to not glitch when overflown vertically. */
  box-sizing: border-box;
  overflow-y: auto;
  opacity: 0.9;
  background-color: var(--rrui-slide-out-menu-background-color);
  color: var(--rrui-slide-out-menu-text-color);
  transform: translate3d(-100%, 0, 0);
  transition: transform var(--rrui-slideout-menu-animation-duration) ease-out, box-shadow var(--rrui-slideout-menu-animation-duration) ease-out; }

.rrui__slideout-menu--fullscreen {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.rrui__slideout-menu--expanded {
  box-shadow: 0 0 0.2em rgba(0, 0, 0, 0.4); }

.rrui__slideout-menu--left {
  left: 0;
  top: 0;
  bottom: 0;
  transform: translate3d(-100%, 0, 0); }

.rrui__slideout-menu--left.rrui__slideout-menu--fullscreen {
  right: 0; }

.rrui__slideout-menu--left.rrui__slideout-menu--expanded {
  transform: translate3d(0, 0, 0); }

.rrui__slideout-menu--right {
  right: 0;
  top: 0;
  bottom: 0;
  transform: translate3d(100%, 0, 0); }

.rrui__slideout-menu--right.rrui__slideout-menu--fullscreen {
  left: 0; }

.rrui__slideout-menu--right.rrui__slideout-menu--expanded {
  transform: translate3d(0, 0, 0); }

.rrui__slideout-menu--top {
  left: 0;
  right: 0;
  top: 0;
  transform: translate3d(0, -100%, 0); }

.rrui__slideout-menu--top.rrui__slideout-menu--fullscreen {
  bottom: 0; }

.rrui__slideout-menu--top.rrui__slideout-menu--expanded {
  transform: translate3d(0, 0, 0); }

.rrui__slideout-menu--bottom {
  left: 0;
  right: 0;
  bottom: 0;
  transform: translate3d(0, 100%, 0); }

.rrui__slideout-menu--bottom.rrui__slideout-menu--fullscreen {
  top: 0; }

.rrui__slideout-menu--bottom.rrui__slideout-menu--expanded {
  transform: translate3d(0, 0, 0); }

.rrui__slideout-menu__item {
  display: block;
  height: var(--rrui-list-item-height);
  line-height: var(--rrui-list-item-height);
  padding-left: calc(var(--rrui-collapsible-menu-item-side-padding) * 1.5);
  padding-right: calc(var(--rrui-collapsible-menu-item-side-padding) * 1.5);
  opacity: 0;
  transition: opacity calc(var(--rrui-slideout-menu-animation-duration) / 2) ease-out; }

.rrui__slideout-menu--expanded .rrui__slideout-menu__item {
  opacity: 1; }

.rrui__slideout-menu__item:hover {
  text-decoration: none; }

.rrui__slideout-menu__item:active {
  background-color: var(--rrui-slide-out-menu-background-color-active);
  color: var(--rrui-slide-out-menu-text-color-active); }

.rrui__slideout-menu--fullscreen .rrui__slideout-menu__item {
  text-align: center; }

.rrui__snackbar__container {
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 1;
  margin: var(--rrui-snackbar-margin);
  transition: all 225ms cubic-bezier(0, 0, 0.2, 1);
  transform: translateY(0);
  visibility: visible; }

.rrui__snackbar__container--hidden {
  transition: all 195ms cubic-bezier(0.4, 0, 0.6, 1);
  visibility: hidden; }

.rrui__snackbar {
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 0;
  pointer-events: auto;
  background: var(--rrui-snackbar-background-color);
  color: var(--rrui-snackbar-text-color);
  border-radius: var(--rrui-border-radius-small);
  padding-left: var(--rrui-snackbar-side-padding);
  padding-right: var(--rrui-snackbar-side-padding);
  height: var(--rrui-snackbar-height); }

.rrui__snackbar__text {
  overflow: 'hidden'; }

.rrui__snackbar__count {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  min-width: var(--rrui-snackbar-notifications-count-size);
  height: var(--rrui-snackbar-notifications-count-size);
  padding-left: 0.3em;
  padding-right: 0.3em;
  transform: translateX(50%) translateY(50%);
  border-radius: calc(var(--rrui-snackbar-notifications-count-size) / 2);
  background-color: var(--rrui-snackbar-notifications-count-background-color);
  color: var(--rrui-white-color); }

.rrui__switch {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent; }

.rrui__switch--disabled {
  cursor: default; }

.rrui__switch--label {
  display: inline-flex;
  align-items: center; }

.rrui__switch__switch {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  line-height: 0; }

.rrui__switch__input {
  left: -30%;
  right: -30%;
  width: 160%;
  height: 180%;
  cursor: pointer; }

.rrui__switch__label--left {
  margin-right: var(--rrui-control-label-margin); }

.rrui__switch__label--right {
  margin-left: var(--rrui-control-label-margin); }

.rrui__switch__groove {
  position: relative;
  display: inline-block;
  width: 1.65em;
  height: 1em;
  color: var(--rrui-switch-accent-color);
  background-color: var(--rrui-switch-background-color);
  box-shadow: inset 0 0 0 0.0625em rgba(0, 0, 0, 0.16);
  border-radius: 0.5em;
  transition: all 0.4s cubic-bezier(0.17, 0.67, 0.43, 0.98); }

.rrui__switch__input:focus + .rrui__switch__groove {
  box-shadow: inset 0 0 0 0.0625em rgba(0, 0, 0, 0.16), var(--rrui-switch-focus-outline-shadow); }

.rrui__switch__groove--on {
  box-shadow: inset 0 0 0 0.73em currentColor; }

.rrui__switch__input:focus + .rrui__switch__groove--on {
  box-shadow: inset 0 0 0 0.73em currentColor, var(--rrui-switch-focus-outline-shadow); }

.rrui__switch__knob {
  position: absolute;
  width: 0.875em;
  height: 0.875em;
  border-radius: 0.4375em;
  top: 0.0625em;
  left: 0.0625em;
  background-color: var(--rrui-switch-background-color);
  box-shadow: inset 0 0 0 0.03em rgba(0, 0, 0, 0.3), inset 0 0 0 0.03em transparent, 0 0 0.05em rgba(0, 0, 0, 0.05), 0 0.1em 0.2em rgba(0, 0, 0, 0.2);
  transition: all 0.25s ease-out; }

.rrui__switch__input:focus + .rrui__switch__groove + .rrui__switch__knob {
  box-shadow: inset 0 0 0 0.03em rgba(0, 0, 0, 0.3), inset 0 0 0 0.03em var(--rrui-switch-knob-border-color-focus), 0 0 0.05em rgba(0, 0, 0, 0.05), 0 0.1em 0.2em rgba(0, 0, 0, 0.2); }

.rrui__switch__knob--on {
  left: 0.7125em; }

.rrui__switcher {
  position: relative;
  white-space: nowrap; }

.rrui__switcher .rrui__input {
  display: flex; }

.rrui__switcher__option {
  flex: 1;
  height: 100%;
  padding-left: calc(var(--rrui-unit) * 1.5);
  padding-right: calc(var(--rrui-unit) * 1.5);
  background-color: var(--rrui-switcher-background-color);
  color: var(--rrui-switcher-text-color);
  border: 1px solid var(--rrui-switcher-text-color);
  border-radius: var(--rrui-border-radius-big);
  transition: all 50ms ease-out; }

.rrui__switcher__option--first {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none; }

.rrui__switcher__option--last {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.rrui__switcher__option--middle {
  border-radius: 0;
  border-right: none; }

.rrui__switcher__option:focus:not(:active):not(.rrui__switcher__option--selected) {
  color: var(--rrui-switcher-text-color); }

.rrui__switcher__option:active {
  color: var(--rrui-switcher-text-color-active);
  background-color: var(--rrui-switcher-background-color-active);
  /*border-color     : var(--rrui-switcher-background-color);*/
  transition: none; }

.rrui__switcher__option--selected {
  color: var(--rrui-switcher-text-color-selected);
  background-color: var(--rrui-switcher-background-color-selected);
  /*border-color     : var(--rrui-switcher-background-color-selected);*/ }

.rrui__switcher__option {
  cursor: pointer; }

.rrui__switcher__option--disabled {
  cursor: default; }

.rrui__text-input__label--invalid {
  color: var(--rrui-input-field-label-color-error); }

.rrui__input-field:not(:focus) + .rrui__text-input__label--placeholder {
  transform: translateX(var(--rrui-input-field-side-padding)) translateY(0) scale(1);
  /* Introducing a return animation delay */
  /* Because when a user clicks/taps on an option */
  /* Then the input loses focus */
  /* Causing the return animation to start playing */
  /* and then revert back as the click finishes */
  /* returning focus to the input. */
  transition-delay: 100ms; }

/* Set the default height of multiline `<TextInput/>`s. */
.rrui__input-field--multiline {
  height: var(--rrui-multiline-text-height);
  padding-top: var(--rrui-multiline-text-input-vertical-padding);
  padding-bottom: var(--rrui-multiline-text-input-vertical-padding);
  padding-left: var(--rrui-input-field-side-padding);
  padding-right: var(--rrui-input-field-side-padding); }

/* Multiline `<TextInput/>`'s label positioning. */
/* Must be same as `padding-top`/`padding-bottom`. */
.rrui__text-input__label--multiline {
  top: var(--rrui-multiline-text-input-vertical-padding); }

.rrui__tooltip {
  display: flex;
  align-items: center;
  padding-left: var(--rrui-tooltip-side-padding);
  padding-right: var(--rrui-tooltip-side-padding);
  height: var(--rrui-tooltip-height);
  margin-top: calc(var(--rrui-unit) * -1);
  color: var(--rrui-tooltip-text-color);
  background-color: var(--rrui-tooltip-background-color);
  border-radius: var(--rrui-border-radius-small);
  cursor: default; }

.rrui__tooltip {
  opacity: 0;
  transform: translateY(0.8em) scale(0.97); }

.rrui__tooltip--after-show {
  opacity: 1;
  transform: translateY(0) scale(1); }

.rrui__tooltip--before-hide {
  opacity: 0;
  transform: translateY(0.8em) scale(0.97); }

.rrui__tooltip--after-show {
  transition: opacity 90ms ease-out, transform 200ms ease-out; }

.rrui__tooltip--before-hide {
  transition: opacity 150ms ease-out, transform 200ms ease-out; }

/* Prevents entering text selection mode
   on mobile devices when tapping down and holding */
.rrui__tooltip__target {
  user-select: none; }
