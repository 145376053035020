.layout-content {
    display: flex;
    align-items: stretch;
}

.layout-header {
    display: flex;
}

.d-flex-menu {
    flex: 1;
}

.current-view {
    flex: 1;
}

.layout-state {
    height: 100%;
}